var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('header', {
    staticClass: "mb-2"
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal Penyusutan")])]), _c('p', [_vm._v(_vm._s(_vm.humanDate(_vm.penyusutan.tanggal)))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tahun")])]), _c('p', [_vm._v(_vm._s(_vm.penyusutan.tahun))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Bulan")])]), _c('p', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.penyusutan.bulan ? _vm.penyusutan.bulan : 'kosong'))])]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Total Biaya")])]), _c('p', [_vm._v("Rp. " + _vm._s(_vm.penyusutan.biaya ? _vm.formatRupiah(_vm.penyusutan.biaya) : "-"))])]), _vm.penyusutan.id_akun == null && _vm.penyusutan.kas == null ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmakun();
      }
    }
  }, [_vm._v("Tentukan Sumber Pengeluaran")])], 1) : _vm._e(), _vm.penyusutan.id_akun > 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-primary",
      "size": "sm"
    }
  }, [_vm._v("Selesai dimasukkan ke akun")]), _c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        return _vm.confirmcancel();
      }
    }
  }, [_vm._v("Cancel Akun")])], 1) : _vm._e()], 1)], 1)], 1), _c('main', [_c('b-modal', {
    attrs: {
      "id": "modal-select2",
      "title": "Pilih Akun dan Kas",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": !_vm.isValidForm,
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.simpanAkun($event);
            }
          }
        }, [_vm._v(" Simpan ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalapprfinance,
      callback: function ($$v) {
        _vm.showModalapprfinance = $$v;
      },
      expression: "showModalapprfinance"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Nominal Penyusutan"
    }
  }, [_c('h4', [_c('i', {
    staticClass: "text-success"
  }, [_c('strong', [_vm._v("Rp " + _vm._s(_vm.formatRupiah(_vm.penyusutan.biaya)))])])])]), _c('b-form-group', {
    attrs: {
      "label": "Pilih Akun",
      "label-for": "vue-select-akun"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-1",
    attrs: {
      "id": "vue-select-akun",
      "options": _vm.id_akun
    },
    model: {
      value: _vm.form2.id_akun,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "id_akun", $$v);
      },
      expression: "form2.id_akun"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Pilih Kas",
      "label-for": "vue-select"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-1",
    attrs: {
      "id": "vue-select",
      "options": _vm.id_kas
    },
    model: {
      value: _vm.form2.id_kas,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "id_kas", $$v);
      },
      expression: "form2.id_kas"
    }
  }), _vm.invalidKas ? _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Saldo Kas kurang")]) : _vm._e()], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }