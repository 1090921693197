<template>
    <b-modal id="approve-rincian-modal" no-close-on-backdrop no-close-on-esc size="lg">
        <b-table responsive :fields="fields" :items="rincians">
            <template #cell(no)="row">
                {{ (row.index + 1) }}
            </template>
            <template #cell(nama)="{item}">
                <span v-if="item.asset">
                    {{ item.asset.nama }}
                </span>
                <i v-else class="text-danger">Aset tidak ditemukan</i>
            </template>
            <template #cell(harga_dasar)="{item}">
                Rp {{ item.asset ? formatRupiah(item.asset.harga_dasar) : 0 }}
            </template>
            <template #cell(satuan)="{item}">
                {{ item.asset && item.asset.satuan ? item.asset.satuan.satuan : '-' }}
            </template>
            <template #cell(harga)="{item}">
                Rp {{ formatRupiah( parseInt(item.harga)) }}
            </template>
            <template #cell(status)="{item}">
                <!-- Barang diterima -->
                <feather-icon v-if="item.status == 2" @click="item.status = toggleStatus(item.status)" icon="XIcon" class="text-danger cursor-pointer" size="24" />
                <feather-icon v-else @click="item.status = toggleStatus(item.status)" icon="CheckIcon" class="text-success cursor-pointer" size="24" />
            </template>
        </b-table>
        <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal, BTable} from 'bootstrap-vue'
    export default {
        data: () => ({
            fields: [
                {key: 'no', label: 'No', sortable: true},
                {key: 'nama', label: 'Nama Aset', sortable: true},
                {key: 'harga_dasar', label: 'Harga Dasar', sortable: true},
                {key: 'jumlah', label: 'Jumlah', sortable: true},
                {key: 'satuan', label: 'Satuan', sortable: true},
                {key: 'harga', label: 'Harga Total', sortable: true},
                {key: 'status', label: 'Barang Diterima'}
            ]
        }),
        components: {
            BButton, BModal, BTable
        },
        computed: {
            rincians() {
                return JSON.parse( JSON.stringify(this.$store.state.asset.rincians) )
            }
        },
        methods: {
            toggleStatus(status) {
                if(status == 1) {
                    return 2
                }
                return 1
            },
            submit() {
                const payloads = this.rincians.map(item => ({id: item.id, status: item.status}))
                this.$emit('submitRincian', payloads)
            }
        }
    }
</script>