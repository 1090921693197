var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "xl",
      "id": "modal-asset",
      "title": "Daftar Aset"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "disabled": !_vm.thereSelectedAsset,
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Pilih Aset")])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    attrs: {
      "responsive": "",
      "bordered": "",
      "fields": _vm.fields,
      "items": _vm.localAssets
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedAssets,
            callback: function ($$v) {
              _vm.selectedAssets = $$v;
            },
            expression: "selectedAssets"
          }
        })];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '') + " ")];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" Rp " + _vm._s(_vm.formatRupiah(item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('b-form-input', {
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }